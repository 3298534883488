<template>
    <div class="box">
        <div class="background"></div>
        <Header :navList="navList" @search="handleSearchEvent" :navCurrentId="navCurrentId" :search-style="{width:150}" :typeList="typeList" :logo="logoUrl">
        </Header>
        <a-skeleton active class="content" :loading="loading">
            <div class="content">
                <div class="flex flex-wrap x-left y-top" v-if="dataList.length">
                    <div class="item flex flex-column" v-for="(item,index) in dataList" :key="index" @click="orgJumpPage(item.id)">
                        <div class="top-img flex-item">
                            <img :src="item.index_logo | urlFilter(250,'avatar') || require('@/assets/images/committee-logo.jpg')" alt="">
                            <div class="title">{{item.name}}</div>
                        </div>
                        <div class="info flex y-center x-around">
                            <div class="category">
                                <div class="count">{{item.meeting_count}}</div>
                                <div class="name">会议</div>
                            </div>
                            <div class="category">
                                <div class="count">{{item.live_count}}</div>
                                <div class="name">直播</div>
                            </div>
                            <div class="category">
                                <div class="count">{{item.fans_count}}</div>
                                <div class="name">关注</div>
                            </div>
                            <div class="category">
                                <div class="count">{{numberFormat(item.committee_hits)}}</div>
                                <div class="name">浏览</div>
                            </div>
                        </div>
                    </div>
                </div>
                <a-empty style="margin-top:100px;" v-if="!dataList.length && !loading" />
                <div class="t-c mt20">
                    <a-pagination
                        v-model="paginationParams.page"
                        show-quick-jumper
                        show-size-changer
                        :pageSizeOptions="pageSizeOptions"
                        :pageSize="paginationParams.pageSize"
                        :total="paginationParams.total"
                        @change="getList"
                        @showSizeChange="handleChangeSize"
                        :hideOnSinglePage="true"
                    />
                </div>
            </div>
        </a-skeleton>
        <Footer></Footer>
    </div>
</template>

<script>
    import {organizationList, organizationLogo} from "@/config/const";
    import Header from "@/components/layout/layout-header";
    import Footer from "@/components/layout/layout-footer";
    import storage from 'store'
    export default {
        name: "OrganzationIndex",
        components : {
            Header, Footer,
        },
        data(){
            return {
                logoUrl : organizationLogo,
                navList : organizationList,//顶部菜单
                navCurrentId : 3,//当前页面菜单对应下标
                pageSizeOptions : ['12' , '16' , '22' , '28'],
                paginationParams : {
                    page : 1, //页数
                    pageSize : 12, //当前页展示多少
                    total : 0, // 数据总量
                },
                typeList : [],
                type:'',//类型id
                allDataList : [], //全部数据
                dataList : [],//单页数据
                loading : true,
            }
        },
        created() {
          //this.getType();
          this.getAllList();
        },
        computed:{
          numberFormat(){
              return function (number) {
                  if(!number) return 0;
                  number =number + '';
                  if(number.length >= 5){
                      let offset = number[number.length-3] >= 5 ? 1 : 0;
                      return (parseFloat(number.slice(0,number.length-3)) + offset) / 10 + 'W';
                  }else{
                      return number;
                  }
              }
          }
        },
        methods :{
            getAllList(){
                this.loading = true;
                let data = {};
                this.type && (data['type'] = this.type);
                this.name && (data['name'] = this.name);
                this.request.post('CommitteeList',data).then(res => {
                    this.allDataList = res.data;
                    this.paginationParams.total = res.data.length;
                    this.loading = false;
                    this.getList();
                })
            },
            getList(){
                let { page , pageSize } = this.paginationParams;
                let data = this.allDataList.slice((page - 1) * pageSize, page * pageSize);
                console.log((page - 1) * pageSize, page * pageSize)
                this.dataList = data;
            },
            getType(){
                this.request.post('CommitteeType',{}).then(res => {
                    this.typeList = res.data;
                })
            },
            handleChangeSize(current,size){
                console.log(current,size);
                this.paginationParams.pageSize = size;
                this.getList();
            },
            handleChangeType(e){
                console.log(e,'切换')
                this.type = e;
                this.getAllList();
            },
            handleSearchEvent(e){
                console.log(e,'搜索')
                this.name = e;
                this.paginationParams.page = 1
                this.getAllList();
            },
            orgJumpPage(committee_id) {
                this.request.post('CommitteeDetail', { committee_id: committee_id }).then(res => {
                    let orgContent = res.data.committee
                    storage.set('orgLogoName',JSON.stringify(orgContent))
                    let routeData = this.$router.resolve({
                        path: '/organization/org-homepage',
                        query: {
                            committee_id
                        }
                    });
                    window.open(routeData.href, '_blank');
                })
            },
        }
    }
</script>

<style scoped lang="less">
    .background{
        width: 100%;
        height: 408px;
        background: linear-gradient(360deg, rgba(234,249,255,0) 0%, #EAFAFF 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .content{
        width: 1240px;
        min-height: 798px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        margin: 30px auto 0;
        align-content: flex-start;
        .item{
            width: 285px;
            height: 230px;
            border-radius: 10px;
            border: 1px solid #EFF4FF;
            margin-right: 20px;
            margin-bottom: 20px;
            cursor: pointer;
            transition: .3s;
            &:nth-child(4n){
                margin-right: 0;
            }
            .top-img{
                position: relative;
                img{
                    position: absolute;
                    width: 84px;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                }
                .title{
                    color: #333333;
                    text-align: center;
                    position: absolute;
                    bottom: 5px;
                    width: 100%;
                }
            }
            .info{
                background: #EFF4FF;
                height: 70px;
                transition: all .3s;
                border-radius: 0 0px 10px 10px;
                /*overflow: hidden;*/
            }
            &:hover{
                border-color: rgba(0,0,0,.09);
                box-shadow: 0 2px 8px rgba(0,0,0,.09);
                .info{
                    color: #ffffff;
                    background: linear-gradient(360deg, #5F96E6 0%, #1E51C9 100%);
                }
            }
        }
    }
    .search{
        .ant-input-wrapper{
            border-radius:40px;
            border: 1px solid #d9d9d9;
            overflow: hidden;
        }
        .ant-btn-primary{
            background: @theme;
            width:60px;
            border-radius:40px!important;
        }
        .ant-input{
            border:0;
            outline: none;
            padding-left:20px;
            border-radius:40px;
            &:focus{
                box-shadow: none;
            }
        }
        .anticon-search{
            font-size:22px;
            height: 18px;
            overflow: hidden;
        }

        /deep/ .ant-input-group-addon{
            border: none;
            background: #FFFFFF;
        }
    }
    
    @media screen and (max-width:768px){
        /deep/.unlogin-header,/deep/.login-header{
            right: 30px!important;
        }
    }
    @media screen and (min-width:651px) and (max-width:768px){
        .content{
            width: 100%;
            margin: 0;
            padding: 10px;
            .item{
                margin-right: 10px!important;
                width: calc((100% - 20px) / 3);
                &:nth-child(3n+3){
                    margin-right: 0!important;
                }
            }
        }
    }
    @media screen and (min-width:550px) and (max-width:650px){
        .content{
            width: 100%;
            margin: 0;
            padding: 10px;
            .item{
                margin-right: 10px!important;
                width: calc((100% - 10px) / 2);
                &:nth-child(2n+2){
                    margin-right: 0!important;
                }
            }
        }
    }
    @media screen and (max-width:549px){
        .content{
            width: 100%;
            margin: 0;
            padding: 10px;
            .item{
                margin-right: 0!important;
                width: 100%;
            }
        }
    }
</style>